<template>
  <div class="main">
    <lj-nav></lj-nav>

    <!-- 同意声明 产品说明  -->
    <div class="query-info-wrapper" v-if="!agreementAgreed">
      <div class="questionnaire">
        <p>
          您好！欢迎使用灯塔智能生涯规划与数据分析系统！在使用系统前，您需要先了解关于本系统的说明。
        </p>
        <p>
          A)
          本网站含有的《专业定位测评报告》，《学科能力测评报告》，《选科规划报告》是基于公开权威测评量表以及学生的心理特征与学科能力产生的。它们基于客观事实以及智能算法得到，可帮助学生进行高效选科，并给出适合学生的大学专业与学校建议。
        </p>
        <p>
          B)
          请在相对安静无打扰的环境进行以上测评，以确保得到高质量可靠的测评结果，否则可能影响测评的准确性。
        </p>
        <p>
          C)
          本网站含有的测评结果与相关报告可帮助学生进行高效选科，但它们本质上是高效决策工具以及决策辅助手段，选科与选择专业的最终决定权是学生的，这些报告不对学生的最终选择结果负责。
        </p>
        <p>
          D)
          本网站包含的测评以及测评报告中的部分图片，数据以及文字信息来自互联网公开资料以及权威部门授权。不排除个别的数据信息源不够准确的可能，本网站不对第三方错误信息负责，本网站的所有报告解释权归测评系统开发者所有。
        </p>
        <p>
          E)
          《专业定位测评报告》基于心理学权威量表以及专业智能算法，可以比较客观地反应测评者的学科倾向。由于报告基于客观事实，呈现的结果可能与测评者主观自我认知不完全一致。
        </p>
        <p>
          F)
          《学科能力测评报告》基于部分高考真题，并通过自主开发的测评算法得到。它反应学生各个学科的客观应试能力，而《专业定位测评报告》中的“优势学科报告”主要反应学生对学科的主观喜好与自我认定的能力，这两者有一定联系，但也存在区别。
        </p>
        <div class="button-wrapper">
          <button
            class="lj-btn lj-btn-third"
            @click="onDisagreeBtnClick"
            style="margin-right: 1rem"
          >
            不同意</button
          ><button
            class="lj-btn lj-btn-primary"
            @click="onAgreeBtnClick"
            style="margin-left: 1rem"
          >
            同意
          </button>
        </div>
      </div>
    </div>
    <router-view v-else />
    <lj-footer></lj-footer>
  </div>
</template>

<script>
import LjNav from '../../../components/nav.vue'
import LjFooter from '../../../components/footer.vue'
import { agreeliability, infoquery } from './../../../api/userinfo'
export default {
  name: 'Gaokao',
  data: function () {
    return {
      agreementAgreed: false
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  mounted: function () {
    infoquery({}).then(res => {
      if (res.rescode == 200) {
        console.log(res)
        this.agreementAgreed = res.agreecourseselect
      }
    })
    if (this.isMobile === null) {
      if (window.location.href.indexOf('#reloaded') == -1) {
        window.location.href = window.location.href + '#reloaded'
        window.location.reload()
      }
    }
  },
  methods: {
    /**
     * 产品说明，同意或者不同意
     */
    onDisagreeBtnClick: function () {
      this.$router.back()
    },
    onAgreeBtnClick: function () {
      agreeliability({
        agreecourseselect: 1
      }).then(res => {
        if (res.rescode == 200) {
          this.agreementAgreed = !0
        }
      })
    }
  },
  components: {
    LjNav,
    LjFooter
  }
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/test/index.min.css');

.main {
  border: 0;
  box-sizing: border-box;
  background: #cfd1cc;
  padding-bottom: 0;
}
</style>
